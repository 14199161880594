import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="body-bg">
        <div className="cover-overlay"></div>
        <header className="header">
          <Link to="/" className="header-logo" />
          {/* <Link to="/Contact" className="header-icon-2">
            <i className="fa fa-phone" />
          </Link> */}
        </header>
        <div
          style={{ width: "100%", height: "70vh" }}
          className="d-flex justify-content-center align-items-center text-light text-center"
        >
          <h1 style={{ color: "#a0a0a0", fontWeight: "bold" }}>
            404 Sayfa Bulunamadı.
          </h1>
        </div>
        <div style={{ width: "100%" }} className="footer">
          <Link to="#" className="footer-logo" />
          <p className="boxed-text center-text">nook | Gastropub</p>
          <div className="footer-socials">
            <a href="tel:+903123425555" className="phone-bg">
              <i className="fa fa-phone" />
            </a>
            <a
              href="https://www.facebook.com/HolidayInnAnkaraCukurambar/"
              className="facebook-bg"
            >
              <i className="fa fa-facebook" />
            </a>
            <a
              href="https://www.instagram.com/HiCukurambar/"
              className="instagram-bg color-white"
            >
              <i className="fa fa-instagram" />
            </a>
            <div className="clear" />
          </div>
          <div className="decoration decoration-margins" />
          <a
            rel="noreferrer"
            alt="osicrew"
            target="_blank"
            href="https://osicrew.com"
          >
            <p className="center-text">Made by OSİCREW</p>
          </a>
        </div>
      </div>
    </>
  );
};
export default NotFound;
