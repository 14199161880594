import { BrowserRouter, Route, Routes } from "react-router-dom";
import Alkoller from "./Pages/Alkoller";
import AnaYemekler from "./Pages/AnaYemekler";
// import BaslangicSalata from "./Pages/BaslangicSalata";
import BurgerDurum from "./Pages/BurgerDurum";
import Home from "./Home";
import Icecekler from "./Pages/Icecekler";
import Kokteyller from "./Pages/Kokteyller";
import PizzaMakarna from "./Pages/PizzaMakarna";
// import Tatli from "./Pages/Tatli";
// import YemeginYaninda from "./Pages/YemeginYaninda";
import Contact from "./Contact";
import NotFound from "./NotFound";

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/Pizzalar" element={<PizzaMakarna />} /> */}
        {/* <Route path="/NookSpecial" element={<AnaYemekler />} /> */}
        {/* <Route path="/BaslangicSalata" element={<BaslangicSalata />} /> */}
        {/* <Route path="/Burger" element={<BurgerDurum />} /> */}
        {/* <Route path="/Tatli" element={<Tatli />} /> */}
        {/* <Route path="/YemeginYaninda" element={<YemeginYaninda />} /> */}
        {/* <Route path="/Kokteyller" element={<Kokteyller />} /> */}
        {/* <Route path="/Alkoller" element={<Alkoller />} /> */}
        {/* <Route path="/Icecekler" element={<Icecekler />} /> */}
        {/* <Route path="/Contact" element={<Contact />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
