import React from 'react'
import HomePage from './HomePage'
import { Intro } from './Intro'

const Home = () => {
  return (
    <>
        {/* <HomePage /> */}
        <Intro />
    </>
  )
}

export default Home